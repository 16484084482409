<template>
  <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page">
    <div v-if="doing" >
      <van-progress :percentage="100 * (q + 1) /question_list.length" stroke-width="6" :show-pivot="false"  color="#569CD6"/>

      <div v-for="( question, i) in question_list" :key="i" >
        <div v-if="i===q">
          <!--问题和选项---->
          <div class="one_question med">
            <div class="blue" v-html="question_list[q].title" ></div>
            <span v-for="(answer, j) in  question_list[q].answers"  :key="j" :class="j===answer_index ?'qingse answer':'answer'" :data-value="j" @click="to_answer" >{{answer.name}}</span>
          </div>

          <!--已经回答---->
          <div v-if="answered" >
            <div class="question_res med" >
              <div>
                <span :class="score_list[q]?'green':'danger'">{{score_list[q]?'答对了':'答错了'}}</span>
                <span class="light">正确率{{ question_list[q].percent }}%</span>
              </div>

              <div class="explain">
                <p class="desc">{{question_list[q].desc}}</p>
                <span  v-for="(tag, i) in  question_list[q].tags"  :key="i"  class="tag qing">{{tag}}</span>
              </div>
            </div>
            <button v-if="q+1 !== question_list.length" class="in_body black w1"  @click="next_question" >下一题</button>
            <button v-else class="in_body black w1"  @click="up_log" >查看答案</button>
          </div>
          <!--回答结束---->
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!paper_info.answer_info.has_score || new_start" >
        <div class="head">
          <div class="title">
            <p >起名盲区测验</p>
          </div>
          <div class="about">
            <p class="l1">人人能看懂，但初次通过率只有{{ paper_info.percent_60 }}%</p>
          </div>
        </div>
        <div class="user_list_center">
          <img v-for="( user, i) in paper_info.user_list" :key="i"  :class="'user sex_' + user.sex" :src="user.avatar"/>
        </div>
        <div class="question_desc">
          <p>共{{paper_info.len_questions}}题，{{paper_info.done_peoples}}人做过</p>
          <p >-- 花样起名 自然语言分析团队 --</p>
        </div>
        <button class="black in_body"  @click="get_question" >开始答题</button>
      </div>
      <div v-else >
        <div class="head">
          <div class="title">
            <p> {{paper_info.answer_info.score.last>=60?"通过测验":"未通过"}}</p>
          </div>

          <div class="about">
            <p class="l1">使用花样起名，规避上述问题</p>
          </div>
        </div>
        <div class="mod">
          <div>
            <span>获得</span><span class="large">{{ paper_info.answer_info.score.last }}</span><span>分，战胜了</span><span class="large">{{paper_info.answer_info.percent }}</span><span>% 的用户</span>
          </div>
          <button class="lightgray in_body" @click="try_again">{{ paper_info.answer_info.score.last >= 60 ? "重新回顾":"再来一次"}}</button>
        </div>
        <button  class="to_be_vip" data-value="exam|" @click="xml_go_to_goods_index">进一步了解大数据起名功能</button>
      </div>
    </div>

    <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Login></Login>
    </van-popup>
    <ul id="last_right_b" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import Login from '@/components/other/Login'

export default {
  name: 'group_exam',
  store,
  components: {
    Login
  },
  data () {
    return {
      show: { zc: false },
      loading: true,
      loading2: false,
      niming: store.state.niming,
      paper_info: {
        answer_info: {
          has_score: false,
          percent: 0,
          score: {}
        },
        user_list: []
      },
      doing: false,
      new_start: false,
      question_list: [],
      q: 0,
      answered: false,
      answer_index: null,
      score_list: []
    }
  },
  mounted () {
    this.get_exam_info()
  },
  methods: {
    get_exam_info () {
      const urlStr = this.niming ? '/exam_paper_detail_unlogin/' : '/exam_paper_detail/'
      const data = { name: this.$route.query.name }
      axios.post(urlStr, data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.paper_info = res.data.data.paper_info
          this.loading = false
        })
    },
    get_question () {
      if (this.niming) {
        this.show.zc = true
        return
      }
      const data = { id: this.paper_info.id }
      axios.post('/exam_paper_question/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.question_list = res.data.data.question_list
          this.doing = true
        })
    },
    to_answer (e) {
      if (this.answered) {
        return
      }
      const i = e.currentTarget.dataset.value
      const answer = this.question_list[this.q].answers[i]

      this.score_list.push(answer.tag)
      this.answered = true
      this.answer_index = i
      const data = { answer: answer.tag, question_id: this.question_list[this.q].id }

      axios.post('/exam_up_question/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log('up_success')
        })
    },

    next_question () {
      this.q = this.q + 1
      this.answered = false
      this.answer_index = null
    },
    up_log () {
      const questionIdList = []
      const questionList = this.question_list
      for (let i = 0; i < questionList.length; i++) {
        questionIdList.push(questionList[i].id)
      }
      const data = { id: this.paper_info.id, score_list: this.score_list, question_id_list: questionIdList }
      axios.post('/exam_up_log/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.paper_info = res.data.data.paper_info
          this.doing = false
          this.new_start = false
          this.q = 0
          this.answered = false
          this.answer_index = null
          this.score_list = []
          this.question_list = []
          // Utils.show_ask_goods({ page: 'exam', tag: '' })
        })
    },

    try_again () {
      this.new_start = true
    },
    on_close () {
      this.show = { zc: false }
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>

.one_question .title{
  font-weight:bold;
  font-size: 0.4rem;
  margin: 0.6rem 0rem;
}

.one_question .answer{
  display: inline-block;
  height: 1.2rem;
  line-height: 1.2rem;
  width: 3.6rem;
  margin: 0.4rem 0.32rem;
  background-color: #f8f8f8;
  border-radius: 1rem;
  text-align: center;
  font-size: 0.5rem;

}
.large{
  font-size: 1rem;
  font-weight: bold;
  padding: 0.4rem 0.1rem;
}
.question_desc{
  text-align: center;
}
.question_desc p{
  margin: 0.6rem 0rem;
  font-size: 0.5rem;
}
.question_res .light{
  margin-left: 0.4rem;
  float: right;
  color: lightgray;
}

.explain div{
  margin: 0.3rem 0rem;

}
.explain .desc{
  display: inline-block;
  line-height: 0.8rem;
  margin: 0.4rem 0rem;
}

.user_list_center{
  text-align: center;
  margin-top:0.2rem;
}

.user_list_center .user{
  width: 1rem;
  height: 1rem;
  margin: 0rem 0.1rem;
  border-radius: 1rem;

}
.user_list_center .sex_2{
  border: 0.1rem solid rgba(255,192,203,0.4);
}
.user_list_center .sex_1{
  border: 0.1rem solid rgba(129,211,248,0.4);
}
.user_list_center .sex_0{
  border: 0.1rem solid rgba(193,197,221,0.6);
}
.med{
  padding: 0.6rem;
  line-height:1.5;
  font-size: 0.45rem;
}
.mod{
  font-size: 0.45rem;
}
.question_title{
  padding-left: 1rem;
}

</style>
